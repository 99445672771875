'use client'

import React, { FC } from 'react'
import { twJoin, twMerge } from 'tailwind-merge'

import { HighlightedText } from './highligted-text'
import { Box } from '../../box'
import { AutocompleteItem } from '../autocomplete-types'
import { useAutocompleteContext } from '../context'
import {selectors} from "@/common/constants/selectors-constants";

export const AutocompleteMenu: FC = () => {
  const { searchValue, items, focusedItemId, events$ } =
    useAutocompleteContext()

  if (!items?.length) {
    return null
  }

  const onHover = (id?: string) => {
    events$.next({
      type: 'focusItem',
      id,
    })
  }

  const onClick = (item: AutocompleteItem) => {
    if (item.isTitle) return
    events$.next({
      type: 'submit',
    })
  }

  return (
    <Box
      data-test={selectors.common.autocomplete.searchDropdown}
      variant="bordered"
      className={twJoin(
        'absolute bg-white z-20 overflow-auto',
        'flex flex-col',
        'w-full max-h-[calc(100vh-200px)] md:max-h-[calc(100vh-120px)]',
        'text-xs leading-4',
        'pl-4 py-[6px]',
      )}
    >
      {items?.map((item) => {
        const classes = twMerge(
          'cursor-pointer flex flex-1 w-full items-center space-x-2 py-1',
          !item.isTitle && 'pl-2',
          item.isTitle && 'font-bold uppercase',
          item.id === focusedItemId && 'underline',
        )
        return (
          <div
            key={item.id}
            className={classes}
            onClick={() => onClick(item)}
            onMouseEnter={() => {
              if (!item.isTitle) {
                onHover(item.id)
              } else {
                onHover()
              }
            }}
          >
            {item.image && (
              <div
                style={{
                  backgroundImage: `url(${item.image})`,
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                }}
                className="h-[70px] w-[70px]"
              />
            )}
            <HighlightedText
              className="flex-1"
              text={item.label}
              match={searchValue}
              disabled={item.isTitle}
            />
          </div>
        )
      })}
    </Box>
  )
}
