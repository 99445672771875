'use client'

import React, { useEffect, useState } from 'react'

import { useIsBrowserMobile } from '@/common/hooks/use-is-browser-resolution'
import { HeaderDesktopNavigation } from './desktop/header-desktop-navigation'
import { HeaderMobileNavigation } from './mobile/mobile-navigation-component'
import { HeaderNavItem } from './navigation-types'

interface HeaderNavigationProps {
  className?: string
  items?: HeaderNavItem[]
  isServerMobile?: boolean
}

export const HeaderNavigation = ({
  className,
  items,
  isServerMobile,
}: HeaderNavigationProps) => {
  const isBrowserMobile = useIsBrowserMobile()
  const navItems = items || []

  const isMobile = isBrowserMobile ?? isServerMobile

  const [isJavaScriptEnabled, setJavaScriptEnabled] = useState<boolean>(false) // INFO: for CSR = true, for SSR and disabled JavaScript = FALSE

  useEffect(() => {
    setJavaScriptEnabled(typeof window !== 'undefined')
  }, [])

  return (
    <nav className={className}>
      {isJavaScriptEnabled && isMobile ? (
        <HeaderMobileNavigation items={navItems} />
      ) : (
        <HeaderDesktopNavigation items={navItems} />
      )}
    </nav>
  )
}
