'use client'
import { FormEvent, useState } from 'react'
import { useTranslations } from 'next-intl'
import { twJoin } from 'tailwind-merge'

import {
  SubscribeEmailToNewsletterMutation,
  useSubscribeEmailToNewsletterMutation,
} from '@/api'
import { useValidateInput } from '@/common/hooks/use-validate-input'
import { Button } from '@/components/button'
import { QuestionMarkRound } from '@/components/icons/svg'
import { Input } from '@/components/input'
import { Tooltip } from '@/components/tooltip'
import { useFlashMessagesContext } from '@/providers'
import { validateEmail } from '@/utils'
import { selectors } from '@/common/constants/selectors-constants'

export const SubscriberEmail = () => {
  const t = useTranslations('Footer')
  const { addFlashMessage } = useFlashMessagesContext()

  const [isFormSubmitting, setIsFormSubmitting] = useState(false)

  const { mutateAsync: subscribeEmailMutateAsync } =
    useSubscribeEmailToNewsletterMutation()

  const {
    value: enteredEmail,
    isEmpty: emailInputIsEmpty,
    valueChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
    reset: emailReset,
  } = useValidateInput(validateEmail)

  const submitFormHandler = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setIsFormSubmitting(true)
    try {
      const data: SubscribeEmailToNewsletterMutation & { errors?: string } =
        await subscribeEmailMutateAsync({
          email: enteredEmail,
        })

      if (data.errors) {
        throw new Error(data.errors)
      }

      addFlashMessage({
        severity: 'success',
        text: t('subscribe.success'),
      })
    } catch {
      addFlashMessage({
        severity: 'error',
        text: t('subscribe.error'),
      })
    } finally {
      emailReset()
      setIsFormSubmitting(false)
    }
  }

  const buttonDisabled = isFormSubmitting || emailInputIsEmpty

  return (
    <div className="w-full pb-5 pt-1">
      <form onSubmit={submitFormHandler}>
        <div className="flex items-center">
          <div
            data-test={selectors.common.footer.emailSubscription}
            className={twJoin(
              'inline-flex w-full mr-0 flex-col',
              'sm:justify-center  sm:flex-row',
            )}
          >
            <label
              htmlFor="subscriberEmail"
              className={twJoin(
                'text-left mx-0 mb-1.5 font-bold leading-4',
                'sm:mr-2.5 sm:-my-0.5 sm:text-right',
              )}
            >
              <span className="inline sm:block">
                {t('subscribe.label.goodEnergy')}{' '}
              </span>
              <span>{t('subscribe.label.yourEmail')}</span>{' '}
            </label>
            <div className="flex">
              <div className="relative w-full mr-1 sm:mr-2">
                <Input
                  id="subscriberEmail"
                  type="email"
                  name="newsletter"
                  placeholder={t('subscribe.input.insertEmail')}
                  className={twJoin(
                    '!h-[30px] w-full border-none text-sm text-black',
                    ' sm:min-w-[300px]',
                  )}
                  required
                  disabled={isFormSubmitting}
                  value={enteredEmail}
                  onBlur={emailBlurHandler}
                  onChange={emailChangeHandler}
                />
                <div
                  className={twJoin(
                    'absolute hidden right-2 top-1',
                    'sm:block',
                  )}
                >
                  <Tooltip
                    text={
                      <div className="w-56 text-black">
                        {t('subscribe.tooltip')}
                      </div>
                    }
                    direction="right"
                  >
                    <QuestionMarkRound width="22px" height="22px" />
                  </Tooltip>
                </div>
              </div>
              <Button
                variant="secondary"
                type="submit"
                size="sm"
                className="px-5 py-[5px] font-bold"
                disabled={buttonDisabled}
              >
                {t('subscribe.button')}
              </Button>
            </div>
          </div>
        </div>
        <div className="block mt-0.5 sm:hidden">{t('subscribe.tooltip')}</div>
      </form>
    </div>
  )
}
