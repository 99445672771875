import { SelectHTMLAttributes, forwardRef } from 'react'

import SvgArrowThinDown from '@/components/icons/svg/arrow-thin-down'
import { Select } from './select-component'

export const CategorySelect = forwardRef<
  HTMLSelectElement,
  SelectHTMLAttributes<HTMLSelectElement>
>(({ children, ...props }, ref) => {
  return (
    <div className="relative inline-block">
      <div className="absolute inset-y-0 flex items-center pointer-events-none pl-2">
        <SvgArrowThinDown className="my-auto fill-white" />
      </div>

      <Select
        ref={ref}
        className="w-full appearance-none border-0 text-white text-sm font-semibold bg-grey-300 pl-7 pr-4 focus:shadow-none"
        {...props}
      >
        {children}
      </Select>
    </div>
  )
})

CategorySelect.displayName = 'CategorySelect'
