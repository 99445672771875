'use client'

import { PropsWithChildren } from 'react'

import { useIsBrowserMobile } from '@/common/hooks/use-is-browser-resolution'

interface MobileOnlyComponentProps extends PropsWithChildren {
  isServerMobile: boolean
}

export function MobileOnlyComponent({
  isServerMobile,
  children,
}: MobileOnlyComponentProps) {
  const isBrowserMobile = useIsBrowserMobile()

  if (isBrowserMobile ?? isServerMobile) {
    return children
  }

  return null
}
