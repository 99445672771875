import { useState } from 'react'
import { twMerge } from 'tailwind-merge'

import { IconButton } from '@/components/icon-button'
import { ArrowSimpleLeftIcon } from '@/components/icons/svg'
import { Text } from '@/components/text'
import { MobileNavigationItems } from './mobile-navigation-items'
import { isActiveChild, useIsActiveCategory } from '../../utils'
import { HeaderNavItem } from '../navigation-types'
import {
  convertAbsoluteUrlToRelative,
  removeUaRuLocalesFromUrl,
} from '@/common/utils/url-utils'
import { Link } from '@/components/link/link'

const getFontWeight = (level?: number) => {
  switch (level) {
    case 0:
      return 'bold'
    case 1:
      return 'semibold'
    default:
      return 'normal'
  }
}

const getFontColor = (level?: number) => {
  switch (level) {
    case 0:
    case 1:
      return 'black'
    default:
      return 'grey500'
  }
}

const getFontCase = (level?: number) => (level === 0 ? 'upper' : 'normal')

export const MobileNavigationItem = (item: HeaderNavItem) => {
  const { level, items, label, href } = item

  const { isActiveCategory, pathname } = useIsActiveCategory()
  const [isOpen, setIsOpen] = useState(() => isActiveChild(pathname, items))

  const hasChildren = !!items && items?.length > 0

  return (
    <div className="flex flex-col">
      <div
        className={twMerge(
          'flex flex-1 justify-between pl-2 py-2',
          isActiveCategory(item)
            ? 'border-l-solid border-l-secondary border-l-[8px]'
            : 'pl-4',
        )}
      >
        <Link
          href={convertAbsoluteUrlToRelative(
            new URL(removeUaRuLocalesFromUrl(href)),
          )}
          className="hover:text-secondary"
        >
          <Text
            weight={getFontWeight(level)}
            color={getFontColor(level)}
            case={getFontCase(level)}
          >
            {label}
          </Text>
        </Link>
        {hasChildren && (
          <IconButton
            className="h-5 mx-2"
            onClick={() => setIsOpen(!isOpen)}
            variant="ghost"
            size="sm"
          >
            <ArrowSimpleLeftIcon
              className={twMerge(
                'transition-all duration-300',
                isOpen ? '-rotate-90' : 'rotate-90',
              )}
            />
          </IconButton>
        )}
      </div>
      <div className={twMerge('flex flex-col pl-2', !isOpen && 'hidden')}>
        <MobileNavigationItems level={(level || 0) + 1} items={items} />
      </div>
    </div>
  )
}
