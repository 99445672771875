'use client'
import { ChangeEvent, useState } from 'react'
import { twJoin } from 'tailwind-merge'

import { Anchor } from '../anchor'
import { Box } from '../box'
import { Button } from '../button'
import { CloseIcon } from '../icons/svg'
import { Modal } from '../modal'
import { Select } from '../select'
import { Text } from '../text'
import { Website } from '../header/components/language-switcher'
import { useGeoLocation } from './use-geo-location'

type StoreNavigatorProps = {
  websites: Website[]
  currentWebsiteUrl?: string
}

export const StoreNavigator = ({
  websites,
  currentWebsiteUrl,
}: StoreNavigatorProps) => {
  const [isModalShown, setIsModalShown] = useState(false)

  const closeModalHandler = () => {
    localStorage.setItem(
      'gb-geo-location',
      String(Date.now() + 24 * 60 * 60 * 1000),
    )
    setIsModalShown(false)
  }

  const selectLocationHandler = (e: ChangeEvent<HTMLSelectElement>) => {
    setChosenLocationUrl(e.target.value)
  }

  const { chosenLocationUrl, geoLocationData, setChosenLocationUrl } =
    useGeoLocation({
      websites,
      setIsModalShown,
      currentWebsiteUrl,
    })

  return isModalShown ? (
    <Modal onClose={closeModalHandler}>
      <Box
        className={twJoin(
          'fixed top-[50px] left-1/2 translate-x-[-50%]',
          'bg-white shadow-[0 0 12px rgba(0, 0, 0, 0.35)]',
          'w-11/12 md:w-[420px]',
          'pt-5 p-8',
          'flex-col animate-slideDown z-20',
        )}
      >
        <Box className="modal-title justify-center mb-5">
          <Text size="lg" className="text-md">
            {geoLocationData?.description}
          </Text>
        </Box>
        <Box className="modal-content justify-center">
          <div className="w-full">
            <Select
              onChange={selectLocationHandler}
              value={chosenLocationUrl}
              className="flex-grow text-sm w-full"
            >
              {websites?.map((item) => {
                return (
                  <option
                    key={item.productUrl ?? item.url}
                    value={item.productUrl ?? item.url}
                  >
                    {item.name}
                  </option>
                )
              })}
            </Select>
            <a href={chosenLocationUrl}>
              <Button
                disabled={!chosenLocationUrl}
                size="base"
                variant="secondary"
                className="w-full mt-3"
              >
                <span className="text-white font-bold">
                  {geoLocationData?.redirect_button_title}
                </span>
              </Button>
            </a>
          </div>
        </Box>
        <footer className="flex justify-center pt-7">
          <Anchor as="button" variant="underline" onClick={closeModalHandler}>
            {geoLocationData?.stay_button_title}
          </Anchor>
        </footer>
        <button
          className="absolute top-3 right-5"
          onClick={(e) => {
            e.preventDefault()
            closeModalHandler()
          }}
        >
          <CloseIcon />
        </button>
      </Box>
    </Modal>
  ) : (
    <div />
  )
}
