export function convertAbsoluteUrlToRelative(absoluteUrl: URL): string {
  return `${absoluteUrl.pathname}${absoluteUrl.search}${absoluteUrl.hash}`
}

export function replaceFirstUaRuLocaleInUrl(url: string): string {
  // Regex pattern to match /ua/something or /ru/something immediately after the URL host
  const pattern = /^(https?:\/\/[^\/]+)\/(ua|ru)(\/[^\/]*)/

  // Replace the matched pattern with the base URL and the rest of the path without /ua or /ru
  const result = url.replace(pattern, '$1$3')

  return result
}

/**
 * Removes `/ua/` and `/ru/` when they appear as part of the URL path.
 * Such edge cases as product names started with `ru` or `ua` are handled =>
 * `/runcollg-mag-gymbeam` will be resolved without changes.
 *
 */
export const removeUaRuLocalesFromUrl = (url: string) =>
  url.replace(/\/(ua|ru)(\/|$)/g, '/')
