'use client'

import { PropsWithChildren } from 'react'

import { useIsBrowserMobile } from '@/common/hooks/use-is-browser-resolution'

interface DesktopOnlyComponentProps extends PropsWithChildren {
  isServerMobile: boolean
}

export function DesktopOnlyComponent({
  isServerMobile,
  children,
}: DesktopOnlyComponentProps) {
  const isBrowserMobile = useIsBrowserMobile()

  if (isBrowserMobile ?? isServerMobile) {
    return null
  }

  return children
}
